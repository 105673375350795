import styled from "styled-components";

export const DSYMainWrapper = styled.div`
    background-color: #F9FAFE;
    padding: 26px;
`;

export const DSYMainWrapperInner = styled.div`
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 100%;
    padding: 26px;

`;


export const DSYDivWithScroll = styled.div<{ maxHeight: number }>`

    overflow: auto;
    max-height: ${(props) => props.maxHeight}px;
    border: 1px solid rgba(0, 0, 0, 0.4);


    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 7px;
    }

    &::-webkit-scrollbar:vertical {
        width: 11px;
    }

    &::-webkit-scrollbar:horizontal {
        height: 11px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 2px solid white; /* should match background, can't be transparent */
        background-color: rgba(0, 0, 0, .5);
    }



`;
export const DSYTable = styled.table`
    border-collapse: collapse;
    width: 100%;
    margin: 0 auto;

    th {
        background-color: #f2f2f2;
        padding: 10px 6px !important;
        font-weight: bold;
    }

    th, td {
        border: 1px solid #ddd;
        padding: 2px 6px;
        text-align: left;
        font-size: 12px;
    }

    tr:nth-child(even) {
        background-color: #fafafa;
    }

    tr:hover {
        background-color: #F3F4F6;
    }



`;
export const DSYForm = styled.form`



    .cta-area {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;

        & a {
            color: gray;
            cursor: pointer;
        }
    }
`;

export const DSYSearchBar = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 16px;

    input[type=text] {
        padding: 8px;
        border: 1px solid #ddd;
        border-radius: 4px;
        min-width: 300px;
        max-width: 500px;
    }

    button {
        background-color: blue;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        padding: 8px 16px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }
`;