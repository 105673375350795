// LineChart.js
import React from 'react';
import {Bar, Line} from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    ScatterController,
    TimeScale,
    Title,
    Tooltip,
} from 'chart.js';

// Register components
ChartJS.register(
    LineElement,
    BarElement,
    CategoryScale,
    LinearScale,
    ScatterController,
    PointElement,
    Title,
    Tooltip,
    Legend, TimeScale
);

export function LineChart({title, type, payload, eventPoints, useFullDateScale}: {
    title: string,
    type: string,
    payload: object[],
    eventPoints?: any[],
    useFullDateScale?: boolean
}) {


    let lowestValue = 0;
    let highestValue = 0;
    let haveEventPoints = eventPoints && eventPoints.length > 0;
    // haveEventPoints = false;


    let labels: string[] = [];
    for (let i = 0; i < payload.length; i++) {
        let item: any = payload[i];
        let start_of_week = item['date'] ?? '';
        labels.push(start_of_week);
    }
    let values: number[] = [];
    for (let i = 0; i < payload.length; i++) {
        let item: any = payload[i];
        let v = item['value'] ?? 0;
        values.push(v);
        if (v < lowestValue) {
            lowestValue = v;
        }
        if (v > highestValue) {
            highestValue = v;
        }
    }
    // Data for the chart
    let middleValue = (highestValue + lowestValue) / 2;
    const data = {
        labels: labels,
        datasets: [

            {
                label: 'Events',
                data: eventPoints?.map(event => ({x: event.date, y: middleValue, extra: event.extra ?? ''})) || [],
                borderColor: 'rgba(255, 99, 132, 1)', // Color for event points
                backgroundColor: 'rgba(255, 99, 132, 1)',
                pointRadius: 6, // Size of the event dots
                type: 'scatter', // Ensures this dataset is rendered as scatter points
                showLine: false, // Prevents lines connecting event points
            },
            {
                label: title,
                data: values,
                borderColor: 'rgba(75, 192, 192, 1)',
                backgroundColor: 'rgba(75, 192, 192, 1)',
                fill: true,
                tension: 0.8, // for curve smoothing
            },
        ],
        options: {
            responsive: true,
        }
    };

    // Options for the chart
    const options = {
        responsive: true,

        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            title: {
                display: true,
                text: title,
            },
            tooltip: {
                callbacks: {
                    // Custom tooltip for event points
                    label: function (tooltipItem: any) {
                        const datasetIndex = tooltipItem.datasetIndex;
                        const dataset = tooltipItem.chart.data.datasets[datasetIndex];

                        // Check if this is the "Events" dataset (event points)
                        if (dataset.label === 'Events') {
                            // console.log('tooltipItem');
                            let dataIndex = tooltipItem.dataIndex;
                            let dataSets = tooltipItem.chart.data.datasets;
                            let thisData = dataSets[datasetIndex].data[dataIndex];
                            // let dateForEvent = eventPoints[dataInx].date;
                            const eventDate = tooltipItem.label; // x-axis date
                            console.info(thisData);
                            let extra = thisData.extra ?? '';
                            return `Event: ${JSON.stringify(extra)}`;
                        } else {
                            return `${dataset.label}: ${tooltipItem.raw}`;
                        }
                    }
                }
            }
        },
        scales: {
            x: (haveEventPoints || useFullDateScale == true) ? {
                type: 'time', // Use time scale for the x-axis
                time: {
                    unit: 'month', // Specify the unit for the time scale
                    tooltipFormat: 'yyyy-MM-dd', // Format for tooltip
                    displayFormats: {
                        month: 'MMM yyyy', // How months should be displayed
                    },
                },
                beginAtZero: false,
            } : {
                beginAtZero: false,
            },
            y: {
                beginAtZero: false,
            },
        },
    };

    switch (type) {
        case 'bar':
            // @ts-ignore
            return <Bar data={data} options={options}
                        width={1000}
                        height={200}
            />;
        default:
            // @ts-ignore
            return <Line data={data} options={options}
                         width={1000}
                         height={200}
            />;
    }

};

export default LineChart;
