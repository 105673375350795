import axios from 'axios';
import {BACKEND_API, BACKEND_COOKIE_ID} from "../config/consts";

export const apiClient = axios.create({
    baseURL: BACKEND_API,
    headers: {
        'Content-Type': 'application/json',
    },
});

apiClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem(BACKEND_COOKIE_ID); // Or get the token from cookies
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

apiClient.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 401) {
            // Redirect to login page
            localStorage.removeItem(BACKEND_COOKIE_ID);
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);

export const login = async (credentials: { email: string; password: string, google: any }) => {
    const response = await apiClient.post('/auth/login', credentials);
    return response.data;
};
