import {useEffect, useRef, useState} from "react";
import {DSYTable} from "../../styles/GlobalStyles";
import useOnceEffect from "../../hooks/userOnceEffect";
import {bulkAddComments, fetchCommentsCampaignsById, updateCommentById} from "../../services/apiComments";
import {updateCampaignProperties} from "../../services/apiCampaignsService";


export function CommentsEditPage({campaign, onCloseCallback, onUpdateItem}: {
    campaign: any,
    onCloseCallback: Function,
    onUpdateItem: Function
}) {


    let sectionsToEdit = [
        {'title': 'Name', 'input_type': 'text', 'value': '', 'name': 'name'},
        {'title': 'Platform', 'input_type': 'radio', 'value': '', 'name': 'platforms_id'},
        {'title': 'URL', 'input_type': 'text', 'value': '', 'name': 'url'},
        {'title': 'Image', 'input_type': 'file', 'accept_types': 'image/*', 'value': '', 'name': 'image'},

        {'title': 'Total Budget', 'input_type': 'number', 'value': '', 'name': 'purse'},
        {'title': 'Reward: Cash', 'input_type': 'number', 'value': '', 'name': 'cash_value'},
        {'title': 'Action/Reward', 'input_type': 'select', 'value': '', 'name': 'single_action_kind'},
        {'title': 'Sponsor', 'input_type': 'select', 'value': '', 'name': 'sponsor'},

        {'title': 'Start Date (Time Zone: EST)', 'input_type': 'datetime-local', 'value': '', 'name': 'date_start_EST'},
        {'title': 'End Date (Time Zone: EST)', 'input_type': 'datetime-local', 'value': '', 'name': 'date_end_EST'},
        {'title': 'Active', 'input_type': 'checkbox', 'value': 'checked', 'name': 'active'},
        {
            'title': 'Only Visible to Internal Team',
            'input_type': 'checkbox',
            'value': 'checked',
            'name': 'only_visible_to_team'
        },


    ]

    const [didLoadData, setDidLoadData] = useState(false);
    const [showAddComment, setShowAddComment] = useState(false);
    const [comments, setComments] = useState([]);
    const [values, setValues] = useState<any>({});
    const refBulkImport = useRef<HTMLTextAreaElement>(null);
    const [editingComment, setEditingComment] = useState<any>(null);
    const [tempEditingCommentText, setTempEditingCommentText] = useState('');
    const getInitialData = async () => {
        try {
            const data = await fetchCommentsCampaignsById(campaign.uuid);
            setComments([]);
            if (data.hasOwnProperty('items')) {
                let items = data['items'];
                setComments(items);
            }
        } catch (error) {
            console.error('Error fetching campaign:', error);
        }
    };

    useOnceEffect(() => {
        getInitialData();
    });


    useEffect(() => {
        let data: any = {};
        sectionsToEdit.forEach((section) => {
            data[section.name] = campaign[section.name];
        });
        setValues(data);
        setDidLoadData(true);

    }, []);


    const handleCancel = (e: any) => {
        e.preventDefault();
        onCloseCallback();
    }
    const handleBulkImport = async (e: any) => {
        e.preventDefault();

        let text = (refBulkImport.current?.value ?? '').split('\n');
        console.log('comments', text);
        let dataToSend = [];
        for (let x in text) {
            dataToSend.push({'comment': text[x]});
        }
        var result = await bulkAddComments(campaign.uuid, dataToSend);
        if (result.status === 'error') {
            alert(result.message);
        } else {
            setShowAddComment(false);
            getInitialData();
        }

    }

    const handleEditComment = (comment: any) => {
        console.log('edit comment', comment);
        setTempEditingCommentText(comment['comment']);
        setEditingComment(comment);
    }
    const handleActiveComment = (comment: any) => {
        console.log('active comment', comment);
    }
    const handleFormSave = async (e: any) => {
        e.preventDefault();
    }
    const handleToggleForceComments = async () => {
        console.log('force comment');
        let force = campaign['force_comment_selection'] === 1 ? 0 : 1;
        updateCampaignProperties(campaign['uuid'], {'force_comment_selection': force});
        campaign['force_comment_selection'] = force;
        setValues({...values, 'force_comment_selection': force});

    }

    const handleSave = async (comment: any) => {
        let text = tempEditingCommentText
        if (!text) {
            alert('Comment cannot be empty');
            return;
        }
        console.log('save comment', comment);
        let sendValues = {
            comment: text,
            campaign_uuid: campaign.uuid,
            uuid: comment['uuid']
        };
        var result = await updateCommentById(comment['uuid'], sendValues);
        console.log('saved', result);

        if (result.status === 'error') {
            alert(result.message);
        } else {
            comment['comment'] = text;
            setComments([...comments]);

        }
        setEditingComment(null);
    }


    if (!didLoadData) {
        return <>
            Loading...
        </>
    }
    return (
        <div style={{
            minWidth: '50vw',
        }}>
            <div
                style={{
                    display: 'flex',
                    gap: 20,
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            ><h2>Comments</h2>
                {showAddComment ? <></> : <button
                    className={'action-button'}
                    onClick={() => setShowAddComment(!showAddComment)}>Add Comments
                </button>}
            </div>

            {showAddComment &&
                <div style={{
                    display: 'flex',
                    gap: 20,
                    flexDirection: 'column',
                    alignItems: 'start',
                    marginBottom: 20,
                    border: '1px dashed blue',
                    padding: 20
                }}>
                    <label>New Comment</label>
                    <b>Paste in a list of comments, one per line, and they will be added to the list of comments for
                        this campaign.</b>
                    <textarea
                        ref={refBulkImport}
                        style={{
                            width: '100%',
                            height: '130px',
                            border: '1px solid #333'
                        }}
                    ></textarea>
                    <div style={{
                        display: 'flex',
                        gap: 20,
                        marginTop: 10
                    }}>
                        <button onClick={() => setShowAddComment(false)}>Cancel</button>

                        <button className={'action-button'} onClick={handleBulkImport}>Add</button>
                    </div>
                </div>}
            <div style={{
                display: 'flex',
                gap: 10,
                flexDirection: 'row',
                alignItems: 'center',
                marginBottom: 20,
                fontWeight: 'bold'
            }}>
                <input type="checkbox"
                       checked={campaign['force_comment_selection'] === 1}
                       onClick={() => {
                           handleToggleForceComments();
                       }}/> FORCE COMMENT SELECTION
            </div>

            <DSYTable style={{
                margin: '0',
                width: 'auto'
            }}>
                {comments.length === 0 && <tr>
                    <td>No comments found</td>
                </tr>}
                {comments.length > 0 && <tr>
                    <th>Actions</th>
                    <th>Comment</th>
                    <th>Position</th>
                    <th>Actions</th>
                </tr>}
                {comments.map((comment, index) => {
                    let isEditing = editingComment && editingComment['uuid'] === comment['uuid'];
                    return <tr key={index}>
                        <td>
                            {isEditing ? <>
                                    <button onClick={() => {
                                        setEditingComment(null);
                                    }}>Cancel
                                    </button>
                                    <button
                                        className={'action-button'}
                                        onClick={() => {
                                            handleSave(comment);
                                        }}>Save
                                    </button>
                                </> :
                                <button onClick={() => {
                                    handleEditComment(comment);
                                }}>Edit
                                </button>}
                        </td>
                        <td>{
                            isEditing ? <input type={'text'} value={tempEditingCommentText} onChange={(e) => {
                                    setTempEditingCommentText(e.target.value);
                                }}/> :
                                comment['comment'] ?? ''}</td>
                        <td>{comment['position'] ?? ''}</td>
                        <button onClick={() => {
                            handleActiveComment(comment);
                        }}>Active
                        </button>
                        {/*<td>*/}
                        {/*    <button>Move Up</button>*/}
                        {/*    <button>Move Down</button>*/}
                        {/*</td>*/}
                    </tr>
                })}
            </DSYTable>
            <div className={'cta-area'}>
                <a onClick={handleCancel}>Close</a>
            </div>
        </div>
    );
}