import {apiClient} from "./apiService";

export const fetchUsers = async () => {
    const response = await apiClient.get('/users/all');
    return response.data;
};

export const fetchUserById = async (id: string) => {
    const response = await apiClient.get(`/users/${id}`);
    return response.data;
};

export const createUser = async (userData: any) => {
    const response = await apiClient.post('/users', userData);
    return response.data;
};

export const updateUser = async (id: string, userData: any) => {
    const response = await apiClient.post(`/users/update/${id}`, userData);
    return response.data;
};

export const toggleUserActive = async (id: string, value: number) => {
    const response = await apiClient.post(`/users/active/${id}`, {uuid: id, active: value});
    return response.data;
};
