import {apiClient} from "./apiService";


export const fetchCommentsCampaignsById = async (id: string) => {
    const response = await apiClient.post(`/comments/campaign/${id}`, {'uuid': id});
    return response.data;
};


export const updateCommentById = async (id: string, data: any) => {
    const response = await apiClient.post(`/comments/update/${id}`, {'uuid': id, 'payload': data});
    return response.data;
};

export const bulkAddComments = async (id: string, data: any) => {
    const response = await apiClient.post(`/comments/bulk/`, {'uuid': id, 'payload': data});
    return response.data;
};
