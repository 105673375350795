// src/config/navConfig.ts
export const navSections = [
    // {title: 'Home', url: '/'},
    {title: 'Users', url: '/users'},
    {title: 'Campaigns', url: '/campaigns'},
    // {title: 'Campaigns Off App', url: '/campaigns-off-app'},
    {title: 'Sponsors', url: '/sponsors'},
    {title: 'Boosts', url: '/boosts'},
    {title: 'Analytics', url: '/analytics'},
    {title: 'Logout', url: '/logout'},
];
