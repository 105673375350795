import {useState} from "react";
import {useParams} from "react-router-dom";

export function ReportsPage() {
    const [didLoad, setDidLoad] = useState(true);
    const [htmlString, setHtmlString] = useState('');

    const {campaignsUUID} = useParams();

    if (!didLoad) {
        return (
            <div>Loading...</div>
        );
    }
    const source = `https://daisypay.io/__back_team_cms__/reports/campaign/?uuid=` + campaignsUUID + `&new_cms=11`;
    return (
        <>
            <iframe
                style={{
                    width: '100%',
                    height: '100%',
                    border: 'none'
                }}
                src={source} width="100%" height="1000px"/>
        </>
    );
}