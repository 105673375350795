import {apiClient} from "./apiService";

export const fetchCampaigns = async () => {
    const response = await apiClient.get('/campaigns/all');
    return response.data;
};

export const fetchCampaignsById = async (id: string) => {
    const response = await apiClient.get(`/campaigns/${id}`);
    return response.data;
};

export const createCampaign = async (userData: any) => {
    const response = await apiClient.post('/campaigns', userData);
    return response.data;
};

export const updateCampaign = async (id: string, userData: any) => {
    const response = await apiClient.post(`/campaigns/update/${id}`, userData);
    return response.data;
};

export const usersSelectedForCampaignId = async (id: string, data: any) => {
    const response = await apiClient.post(`/campaigns/users-selected/${id}`, data);
    return response.data;
};
export const usersUpdateSelectedForCampaignId = async (id: string, data: any) => {
    const response = await apiClient.post(`/campaigns/users-selected-update/${id}`, data);
    return response.data;
};


export const updateCampaignProperties = async (id: string, keyValue: object) => {
    const response = await apiClient.post(`/campaigns/update-properties/`, {
        'uuid': id,
        'keyValue': keyValue
    });
    return response.data;
}