import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {useRef, useState} from "react";
import useOnceEffect from "../hooks/userOnceEffect";
import {DSYMainWrapper, DSYMainWrapperInner, DSYSearchBar, DSYTable} from "../styles/GlobalStyles";
import {sortItems} from "../components/Utils";
import {fetchSponsors} from "../services/apiSponsors";
import {SponsorsEditPage} from "./subsection/SponsorsEditPage";

export function SponsorsPage() {
    const [didLoad, setDidLoad] = useState(false);
    const [sponsors, setSponsors] = useState<any>([]);
    const [selectedSponsor, setSelectedSponsor] = useState<any>({});
    const [filteredSponsors, setFilteredSponsors] = useState<any>([]);
    const searchRef = useRef<HTMLInputElement>(null);
    const [sortBy, setSortBy] = useState('name');

    const getItems = async () => {
        try {
            const data = await fetchSponsors();
            setSponsors([]);
            if (data.hasOwnProperty('items')) {
                let items = data['items'];
                setSponsors(items);
                setFilteredSponsors(items);
            }
            setDidLoad(true);

        } catch (error) {
            console.error('Error fetching :', error);
        }
    };

    useOnceEffect(() => {
        getItems();
    });


    let onEditItem = (item: any) => {
        setSelectedSponsor(item);
        handleClickOpen();

    }

    const fullScreen = false;
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (event: object, reason: String) => {
        console.log("reason: " + reason);
        if (reason === 'backdropClick') {
            // return;
        }
        setOpen(false);
    };

    const hanleOnCloseRequest = (e: any) => {
        console.log("hanleOnCloseRequest");
        console.log(e);
        setOpen(false);
        if (e && e.hasOwnProperty('reload') && e['reload'] === true) {
            //reload
            getItems();
        }
    }

    const handleSearch = () => {
        let search = searchRef.current?.value;
        filterItems(search);
    }
    const handleUpdateItem = (item: any) => {

        //update user
        //updaet the user in the list
        //find user and update
        let index = sponsors.findIndex((u: any) => u.uuid === item.uuid);
        if (index > -1) {
            let updated: any[] = [...sponsors];
            updated[index] = item;
            setSponsors(updated);
            setFilteredSponsors(updated);
        } else {
            //reload page
            getItems();
        }
    }

    const filterItems = (search?: string) => {
        if (!search) {
            sortItems(sortBy, sponsors);
            setFilteredSponsors(sponsors);
            return;
        }
        let term = search.toLowerCase();
        let fieldsToSearch = ['name'];
        let filtered = sponsors.filter((user: any) => {
            if (user != null) {
                let match = false;
                fieldsToSearch.forEach((field) => {
                    if (user[field]) {
                        if (user[field].toLowerCase().includes(term)) {
                            match = true;
                        }
                    }
                });
                if (match) {
                    return true;
                }
            }
            return false;
        });
        //sort
        sortItems(sortBy, filtered);
        setFilteredSponsors(filtered);
    }


    const handleSortByClick = (field: string) => {
        if (sortBy === field) {
            setSortBy(field + '_desc');
        } else {
            setSortBy(field);
        }
        filterItems();
    }


    return (
        <DSYMainWrapper>
            <DSYMainWrapperInner>
                <h2>Sponsors</h2>
                <button onClick={() => {
                    onEditItem({'uuid': 'new'});
                }}>Add Sponsor
                </button>
                <br/><br/><br/>
                <DSYSearchBar>
                    <input type="text" ref={searchRef} placeholder="Search..." onKeyUp={handleSearch}/>
                    {/*<button>Search</button>*/}
                </DSYSearchBar>
                {open && <Dialog
                    fullScreen={fullScreen}
                    open={open}

                    // fullWidth={true}
                    maxWidth="lg"
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogContent>
                        {selectedSponsor && <SponsorsEditPage sponsor={selectedSponsor}
                                                              onUpdateItem={handleUpdateItem}
                                                              onCloseCallback={hanleOnCloseRequest}/>}
                    </DialogContent>
                </Dialog>}
                <DSYTable>
                    <thead>
                    <tr>{
                        [
                            {'title': 'Options', 'field': 'options', 'sort': false},
                            {'title': 'Name', 'field': 'name', 'sort': true},
                            {'title': 'Description', 'field': 'description', 'sort': true},
                            {'title': 'URL', 'field': 'url', 'sort': true},
                            {'title': 'Image', 'field': 'img_url', 'sort': false},
                            // {'title': 'Options', 'field': 'options', 'sort': false},
                        ].map((field) => {
                                let active = sortBy === field['field'] || sortBy === field['field'] + '_desc';
                                let canSort = field['sort'] !== false;
                                return (
                                    <th
                                        key={field['field']}
                                        style={
                                            canSort ?
                                                {
                                                    cursor: 'pointer',
                                                    userSelect: 'none',
                                                    color: active ? 'blue' : 'black'
                                                } : {}}
                                        onClick={() => canSort ? handleSortByClick(field['field']) : null}>{field['title']}</th>
                                );
                            }
                        )
                    }
                    </tr>
                    </thead>
                    <tbody>
                    {filteredSponsors.map((item: any) => {
                        return (
                            <tr key={item['uuid']}>
                                <td>
                                    <button onClick={() => {
                                        onEditItem(item);
                                    }}>Edit
                                    </button>
                                </td>
                                <td style={{
                                    fontWeight: 'bold',
                                }}>{item['name']}</td>
                                <td style={{
                                    maxWidth: '200px',
                                    minWidth: '200px',
                                    whiteSpace: 'wrap',
                                    wordBreak: 'break-all',
                                    fontSize: '10px',
                                }}>{item['description']}</td>
                                <td style={{
                                    maxWidth: '200px',
                                    minWidth: '200px',
                                    whiteSpace: 'wrap',
                                    wordBreak: 'break-all',
                                    fontSize: '10px',
                                }}>{item['url']}</td>
                                <td>{
                                    item['img_url'] && <img src={item['img_url']} width={30} height={30} style={
                                        {
                                            objectFit: 'scale-down',
                                        }
                                    }/>
                                }</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </DSYTable>
            </DSYMainWrapperInner>
        </DSYMainWrapper>
    );
}