// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAuth, GoogleAuthProvider, signInWithPopup, signOut} from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAyRGrJxodBG-EW3jrpGFqxVfypcArRkYU",
    authDomain: "daisy-cms-3bce2.firebaseapp.com",
    projectId: "daisy-cms-3bce2",
    storageBucket: "daisy-cms-3bce2.appspot.com",
    messagingSenderId: "99577885678",
    appId: "1:99577885678:web:ec6ef1bf67c9f8548454ff",
    measurementId: "G-GGQ39EVC92"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export {auth, provider, signInWithPopup, signOut};