import {NavLink} from "react-router-dom";
import {navSections} from "../config/navConfig";
import styled from "styled-components";


const Nav = styled.nav`
    background-color: #2C333C;
    height: 100vh;
    flex: 0 0 150px;
    padding: 20px;
    overflow-y: auto;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
`;

const NavList = styled.ul`
    list-style: none;
    padding: 0;
`;

const NavItem = styled.li`
    margin-bottom: 10px;
`;

const StyledNavLink = styled(NavLink)`
    text-decoration: none;
    color: #798696;
    padding: 10px;
    display: block;
    border-radius: 4px;

    &:hover {
        background-color: #007bff22;
        color: white;
    }

    &.active {
        font-weight: bold;
        color: white;
        background-color: #007bff;
    }


`;

export function SideNav() {

    const activeStyle = {
        fontWeight: 'bold',
        color: 'red',
    };


    return (
        <>
            <Nav>
                <div style={{
                    display: 'flex',
                    gap: '4px',
                    color: 'white',
                    fontSize: '24px',
                    fontWeight: 'bold',
                    paddingBottom: '24px',
                    marginBottom: '24px',
                    borderBottom: '1px solid #798696cc',

                }}>
                    <img src="https://daisypay.io/assets/img/daisy-pay-logo.svg" alt="logo" style={{width: '32px'}}/>
                    Daisy
                </div>
                <NavList>
                    {navSections.map((section) => (
                        <NavItem key={section.url}>
                            <StyledNavLink
                                to={section.url}
                                className={({isActive}) => (isActive ? 'active' : '')}>
                                {section.title}
                            </StyledNavLink>
                        </NavItem>
                    ))}
                </NavList>
            </Nav>
        </>
    );
}