import {SideNav} from "./SideNav";
import React from "react";
import styled from "styled-components";
import {Navigate, useLocation} from "react-router-dom";
import {BACKEND_COOKIE_ID} from "../config/consts";

const LayoutContainer = styled.div`
    display: flex;
    min-height: 100vh;
    max-height: 100vh;
    height: 100%;
    overflow: hidden;
    flex-direction: column;

    h2 {
        margin: 0;
        padding: 10px;
        margin-bottom: 10px;
    }

    h3 {
        margin: 0;
        color: #333;
        margin-bottom: 10px;
    }
`;

const LayoutContent = styled.div`
    display: flex;
    min-height: 100vh;
    height: 100%;
    flex-direction: row;
`;

const LayoutChild = styled.div`
    overflow: auto;
    width: 100%;
`;

export const DSYButton = styled.button`
    border: 1px solid #ccc;
    padding: 5px;
    //font-size: 20px;
    margin: 0 5px;

    &.active {
        background-color: green;
        color: white;
    }

    &.paused {
        background-color: darkred;
        color: white;
    }
`;


export function Layout({children}: { children: React.ReactNode }) {

    const token = localStorage.getItem(BACKEND_COOKIE_ID);
    const location = useLocation();
    if (!token) {
        return <Navigate to="/login" state={{from: location}}/>;
    }
    return (
        <LayoutContainer>
            <LayoutContent>
                <SideNav/>
                <LayoutChild>{children}</LayoutChild>
            </LayoutContent>
        </LayoutContainer>
    );
}