import React from "react";
import {DSYDivWithScroll, DSYMainWrapper} from "../styles/GlobalStyles";
import useOnceEffect from "../hooks/userOnceEffect";
import {analyticsFetchDashboard} from "../services/apiAnalyticsService";
import LineChart from "../components/charts/LlineChart";
import styled from "styled-components";

export const AnalyticsPage = () => {

    const [dashboardData, setDashboardData] = React.useState<any>(null);
    const [didLoad, setDidLoad] = React.useState(false);
    const [userByDay, setUserByDay] = React.useState<any>([]);

    const getDashboardData = async () => {
        try {
            const data = await analyticsFetchDashboard();
            setDashboardData([]);
            setUserByDay([]);
            if (data.hasOwnProperty('users_by_day')) {
                let items = data['users_by_day'];
                setUserByDay(items);

            }
            setDidLoad(true);

        } catch (error) {
            console.error('Error fetching :', error);
        }
    };


    useOnceEffect(() => {
        getDashboardData();
    });

    const sections = [
        {
            "title": "Downloads",
            children: [
                {"title": "Total Downloads", "value": "1000"},
                {"title": "iOS Downloads", "value": "500"},
                {"title": "Android Downloads", "value": "500"}
            ]
        },
        {
            "title": "Sign Ups",
            children: [
                {"title": "Total Sign Ups", "value": "1000"},
                {"title": "New sign ups per week", "value": "500"},
                {"title": "New sign ups per month", "value": "500"}
            ]
        },
        {
            "title": "Users",
            children: [
                {"title": "Total Users", "value": "1000"},
                {"title": "Active Users", "value": "500"},
                {"title": "Inactive Users", "value": "500"}
            ]
        },
        {
            "title": "Active Users",
            children: [
                {"title": "Total Active Users", "value": "1000"},
                {"title": "Monthly active users (MAU)", "value": "500"},
                {"title": "Weekly active users (WAU)", "value": "500"},
                {"title": "Daily active users (DAU)", "value": "500"}
            ]
        },
        {
            "title": "Revenue",
            children: [
                {"title": "Total Revenue", "value": "$1000"},
                {"title": "iOS Revenue", "value": "$500"},
                {"title": "Android Revenue", "value": "$500"}
            ]
        }
    ];

    if (!didLoad) {
        return (
            <DSYMainWrapper>
                <h1>Loading...</h1>
            </DSYMainWrapper>
        );
    }

    const totalSignups = userByDay.every((item: any) => item.hasOwnProperty('value')) ? userByDay.reduce((acc: number, item: any) => acc + parseInt(item.value, 10), 0) : 0;

    let runningTotal = 0;

    let SlimTable = styled.table`
        font-size: 12px;
        border-collapse: collapse;

        td, th {
            border: 1px solid #798696cc;
            padding: 8px;
            text-align: left;
        }
    `;

    return (
        <>
            <DSYMainWrapper>
                <h1>Analytics</h1>

                <h3>New Users</h3>
                <div style={{
                    height: '300px',
                    width: '100%',
                    backgroundColor: 'white',
                    display: 'flex',
                    border: '1px solid #798696cc',
                }}>
                    <LineChart
                        type={'bar'}
                        title={`Signups, Total ${totalSignups}`}
                        useFullDateScale={true}
                        payload={userByDay}
                    />
                </div>
                <div>
                    <DSYDivWithScroll maxHeight={300}>
                        <SlimTable style={{}}>
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>New</th>
                                <th>Cumulative</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                //reverse userByDay array to show latest date first

                                userByDay.slice(0).reverse().map((item: any, index: number) => {
                                    let cum = totalSignups - runningTotal;
                                    runningTotal += parseInt(item.value, 10);

                                    return (
                                        <tr key={index}>
                                            <td>{item.date}</td>
                                            <td>{item.value}</td>
                                            <td>{cum}</td>

                                        </tr>
                                    );
                                })}
                            </tbody>
                        </SlimTable>
                    </DSYDivWithScroll>
                </div>

                {/*<div>*/}
                {/*    {sections.map((section, index) => {*/}
                {/*        return (*/}
                {/*            <DSYMainWrapperInner key={index}>*/}
                {/*                <h2>{section.title}</h2>*/}
                {/*                <div>*/}
                {/*                    {section.children.map((child, index) => {*/}
                {/*                        return (*/}
                {/*                            <div key={index}>*/}
                {/*                                <h3>{child.title}</h3>*/}
                {/*                                <p>{child.value}</p>*/}
                {/*                            </div>*/}
                {/*                        );*/}
                {/*                    })}*/}
                {/*                </div>*/}
                {/*            </DSYMainWrapperInner>*/}
                {/*        );*/}
                {/*    })}*/}
                {/*</div>*/}

            </DSYMainWrapper>
        </>
    );
}

