// src/hooks/useOnceEffect.ts
import {useEffect, useRef} from 'react';

function useOnceEffect(effect: () => void) {
    const hasRun = useRef(false);

    useEffect(() => {
        if (!hasRun.current) {
            effect();
            hasRun.current = true;
        }
    }, [effect]);
}

export default useOnceEffect;
