import React, {useEffect, useState} from "react";
import {login} from "../services/apiService";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import {BACKEND_COOKIE_ID} from "../config/consts";
import {auth, provider, signInWithPopup} from "../firebase";
import {signOut} from "firebase/auth";
import {DSYMainWrapper, DSYMainWrapperInner} from "../styles/GlobalStyles";

export const HomePage = () => {

    const token = localStorage.getItem(BACKEND_COOKIE_ID);
    const location = useLocation();
    const [user, setUser] = useState<any>(null);


    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setUser(user);
            } else {
                setUser(null);
            }
        });

        return () => unsubscribe();
    }, []);
    const handleSignOut = async () => {
        try {
            await signOut(auth);
            console.log("Signed out successfully");
        } catch (error) {
            console.error(error);
        }
    };

    const signInWithGoogle = async () => {
        try {
            let token = null;
            if (user) {
                let tempToken = await user.getIdToken();
                if (tempToken) {
                    token = tempToken;
                }
            }
            let result = null;
            if (token === null || user === null) {
                result = await signInWithPopup(auth, provider);
                token = await result.user.getIdToken();
                if (!result.user.email) {
                    alert('Email is required');
                    return;
                }
            }
            let x = null;
            if (result === null && token !== null) {
                x = await login({
                    'email': user.email ?? '',
                    password: '',
                    google: {'token': token, 'full': user.toJSON()}
                });
            } else {
                x = await login({
                    'email': result!.user.email ?? '',
                    password: '',
                    google: {'token': token, 'full': result!.user}
                });
            }

            if (x.hasOwnProperty('token')) {
                let cmsToken = x.token;
                console.log(cmsToken);
                localStorage.setItem(BACKEND_COOKIE_ID, cmsToken); // Or use cookies
                navigate('/users');
            } else {
                alert('Login failed');
            }

        } catch (error) {
            console.error(error);
        }
    };

    return (
        <DSYMainWrapper style={{
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <DSYMainWrapperInner style={{
                width: 'auto',
                display: 'flex',
                gap: '20px',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <div>
                    {user && <>
                        <h3>{user.displayName}</h3>
                        <p>Email: {user.email}</p>
                        <div style={{
                            display: 'inline-flex',
                            flexDirection: 'column',
                            gap: '10px',
                            marginTop: '20px'
                        }}>

                            <button onClick={signInWithGoogle}>Login With {user.email}</button>
                            <button onClick={handleSignOut}>Sign out</button>
                        </div>
                    </>}
                    {!user && <button onClick={signInWithGoogle}>Sign in with Google</button>}
                </div>
            </DSYMainWrapperInner>
        </DSYMainWrapper>
    );


    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const data = await login({email, password, google: null});
            //data needs "token" property
            console.log(data);
            if (!data.hasOwnProperty('token')) {
                console.error('Login failed:', data);
                return;
            }
            let token = data.token;
            console.log(token);
            localStorage.setItem(BACKEND_COOKIE_ID, token); // Or use cookies
            navigate('/users');
        } catch (error) {
            console.error('Login failed:', error);
        }
    };

    if (token) {
        return <Navigate to="/users" state={{from: location}}/>;
    }

    // return (
    //     <>
    //         {token}
    //         {/*<button onClick={signInWithGoogle}>Sign in with Google</button>*/}
    //         <form onSubmit={handleLogin}>
    //             <input
    //                 type="email"
    //                 value={email}
    //                 onChange={(e) => setEmail(e.target.value)}
    //                 placeholder="Email"
    //                 required
    //             />
    //             <input
    //                 type="password"
    //                 value={password}
    //                 onChange={(e) => setPassword(e.target.value)}
    //                 placeholder="Password"
    //                 required
    //             />
    //             <button type="submit">Login</button>
    //         </form>
    //     </>
    // );
}

