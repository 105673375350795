import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {useRef, useState} from "react";
import useOnceEffect from "../../hooks/userOnceEffect";
import {DSYMainWrapper, DSYMainWrapperInner, DSYSearchBar, DSYTable} from "../../styles/GlobalStyles";
import {formatNumber, friendlyDate, sortItems} from "../../components/Utils";
import {SponsorsEditPage} from "./../subsection/SponsorsEditPage";
import {boostByUserUUID} from "../../services/apiBoosts";
import {friendlyCampaignActionName, friendlyPlatformName} from "../../config/consts";
import {useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";

const BEVTable1 = styled.table`
    width: auto;

    th {
        text-align: left;
    }

    td {
        text-align: left;
        border-bottom: 1px solid #ccc;
        font-size: 12px;

        &:first-child {
            font-weight: bold;
            padding-right: 10px;
        }
    }
`;

export function UsersBoostsPage() {
    const [didLoad, setDidLoad] = useState(false);
    const [records, setRecords] = useState<any>([]);
    const [currentUser, setCurrentUser] = useState<any>([]);
    const [selectedBoosts, setSelectedBoosts] = useState<any>({});
    const [filteredRecords, setFilteredRecords] = useState<any>([]);
    const searchRef = useRef<HTMLInputElement>(null);
    const [sortBy, setSortBy] = useState('date_start_desc');
    const navigate = useNavigate();
    const {usersUUID} = useParams();
    const [totalCashValue, setTotalCashValue] = useState(0);

    const getItems = async () => {
        try {
            setRecords([]);
            if (!usersUUID) {
                console.error('No usersUUID');
                return;
            }
            const data = await boostByUserUUID(usersUUID!);
            if (data.hasOwnProperty('user')) {
                setCurrentUser(data['user']);
            }
            if (data.hasOwnProperty('items')) {
                let items = data['items'];
                setRecords(items);
                setFilteredRecords(items);
                sortItems(sortBy, items);
            }

            let cash = data['total_cash'] ?? 0;
            setTotalCashValue(cash);
            setDidLoad(true);
        } catch (error) {
            console.error('Error fetching:', error);
        }
    };

    useOnceEffect(() => {
        getItems();
    });


    const fullScreen = false;
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (event: object, reason: String) => {
        console.log("reason: " + reason);
        if (reason === 'backdropClick') {
            // return;
        }
        setOpen(false);
    };

    const hanleOnCloseRequest = (e: any) => {
        console.log("hanleOnCloseRequest");
        console.log(e);
        setOpen(false);
    }

    const handleSearch = () => {
        let search = searchRef.current?.value;
        filterItems(search);
    }
    const handleUpdateItem = (item: any) => {

        //update user
        //updaet the user in the list
        //find user and update
        let index = records.findIndex((u: any) => u.uuid === item.uuid);
        if (index > -1) {
            let updated: any[] = [...records];
            updated[index] = item;
            setRecords(updated);
            setFilteredRecords(updated);
        } else {
            //reload page
            getItems();
        }
    }

    const filterItems = (search?: string) => {
        if (!search) {
            sortItems(sortBy, records);
            setFilteredRecords(records);
            return;
        }
        let term = search.toLowerCase();
        let fieldsToSearch = ['name'];
        let filtered = records.filter((user: any) => {
            if (user != null) {
                let match = false;
                fieldsToSearch.forEach((field) => {
                    if (user[field]) {
                        if (user[field].toLowerCase().includes(term)) {
                            match = true;
                        }
                    }
                });
                if (match) {
                    return true;
                }
            }
            return false;
        });
        //sort
        sortItems(sortBy, filtered);
        setFilteredRecords(filtered);
    }


    const handleSortByClick = (field: string) => {
        if (sortBy === field) {
            setSortBy(field + '_desc');
        } else {
            setSortBy(field);
        }
        filterItems();
    }

    if (!didLoad) {
        return <div>Loading...</div>
    }


    return (
        <DSYMainWrapper>
            <DSYMainWrapperInner>
                <h2>Boosts</h2>
                <h1>Total: ${formatNumber(totalCashValue)}</h1>
                <div>
                    <BEVTable1>
                        <tr>
                            <td>UUID:</td>
                            <td>{currentUser['uuid']}</td>
                        </tr>
                        <tr>
                            <td>First Name:</td>
                            <td>{currentUser['first_name']}</td>
                        </tr>
                        <tr>
                            <td>Last Name:</td>
                            <td>{currentUser['last_name']}</td>
                        </tr>
                        <tr>
                            <td>Instagram:</td>
                            <td>{currentUser['handle_instagram']}</td>
                        </tr>
                        <tr>
                            <td>Tiktok:</td>
                            <td>{currentUser['handle_tiktok']}</td>
                        </tr>
                        <tr>
                            <td>Email:</td>
                            <td>{currentUser['email']}</td>
                        </tr>
                        <tr>
                            <td>Phone:</td>
                            <td>{currentUser['phone']}</td>
                        </tr>
                        <tr>
                            <td>Created:</td>
                            <td>{friendlyDate(currentUser['created'], 'LL')}</td>
                        </tr>
                    </BEVTable1>


                </div>
                <DSYSearchBar>
                    <input type="text" ref={searchRef} placeholder="Search..." onKeyUp={handleSearch}/>
                </DSYSearchBar>
                <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    // fullWidth={true}
                    maxWidth="lg"
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogContent>
                        {selectedBoosts && <SponsorsEditPage sponsor={selectedBoosts}
                                                             onUpdateItem={handleUpdateItem}
                                                             onCloseCallback={hanleOnCloseRequest}/>}
                    </DialogContent>
                </Dialog>
                <DSYTable style={{
                    margin: '0',
                    width: 'auto',
                }}>
                    <thead>
                    <tr>{
                        [
                            {'title': 'Options', 'field': 'options', 'sort': false},
                            {'title': 'Name', 'field': 'name', 'sort': true},
                            {'title': 'Platform', 'field': 'platforms_id', 'sort': true},
                            {'title': 'Action', 'field': 'single_action_kind', 'sort': true},
                            {'title': 'Date', 'field': 'date_startU', 'sort': true},
                            {'title': 'Status', 'field': 'boost_summary_pending', 'sort': true},
                            {'title': 'Cash', 'field': 'cash_value', 'sort': true},
                            // {'title': 'Approved', 'field': 'boost_summary_approved', 'sort': true},
                            // {'title': 'Rewarded', 'field': 'boost_summary_rewarded', 'sort': true},
                            // {'title': 'Free', 'field': 'boost_summary_free', 'sort': true},

                            // {'title': 'Options', 'field': 'options', 'sort': false},
                        ].map((field) => {
                                let active = sortBy === field['field'] || sortBy === field['field'] + '_desc';
                                let canSort = field['sort'] !== false;

                                return (
                                    <th
                                        key={field['field']}
                                        style={
                                            canSort ?
                                                {
                                                    cursor: 'pointer',
                                                    userSelect: 'none',
                                                    color: active ? 'blue' : 'black'
                                                } : {}}
                                        onClick={() => canSort ? handleSortByClick(field['field']) : null}>{field['title']}</th>
                                );
                            }
                        )
                    }
                    </tr>
                    </thead>
                    <tbody>
                    {filteredRecords.map((item: any) => {
                        let boost = item['boost'] ?? {};
                        let status = boost['status'];
                        let will_receive_reward = boost['will_receive_reward'];
                        if (!will_receive_reward) {
                            status = 'free post';
                        }
                        let dateCreated = boost['created'];
                        // let pending = status === 'pending';
                        // let approved = status === 'approved';
                        // let rewarded = status === 'rewarded';
                        return (
                            <tr key={item['uuid']}>
                                <td>

                                    <button onClick={() => {
                                        navigate('/boosts/' + item['uuid']);
                                    }}>View
                                    </button>
                                </td>
                                <td style={{
                                    fontWeight: 'bold',
                                }}>{item['name']}</td>
                                <td>{friendlyPlatformName(item['platforms_id'])}</td>
                                <td>{friendlyCampaignActionName(item['single_action_kind'])}</td>
                                {/*<td>*/}
                                {/*    <textarea style={{*/}
                                {/*        width: '300px',*/}
                                {/*        height: '200px',*/}
                                {/*        whiteSpace: 'nowrap',*/}
                                {/*        overflow: 'auto',*/}
                                {/*    }}>{JSON.stringify(boost, null, 2)}</textarea>*/}

                                {/*</td>*/}

                                {/*<td>{dateCreated}</td>*/}
                                <td style={{
                                    maxWidth: '200px',
                                    minWidth: '200px',
                                    whiteSpace: 'wrap',
                                    wordBreak: 'break-all',
                                }}>{friendlyDate(dateCreated, 'LL')}</td>
                                <td>{status}</td>
                                <td>${formatNumber(item['cash_value'])}</td>
                            </tr>
                        );
                    })}
                    </tbody>
                </DSYTable>
            </DSYMainWrapperInner>
        </DSYMainWrapper>
    );
}