import React from 'react';
import './App.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";

import {Layout} from "./components/Layout";
import {HomePage} from "./pages/HomePage";
import {UsersPage} from "./pages/UsersPage";
import {CampaignsPage} from "./pages/CampaignsPage";
import {LogoutPage} from "./pages/LogoutPage";
import {SponsorsPage} from "./pages/SponsorsPage";
import {BoostsPage} from "./pages/BoostsPage";
import {BoostsDetailPage} from "./pages/BoostsDetailPage";
import {ReportsPage} from "./pages/ReportsPage";
import {AnalyticsPage} from "./pages/AnalyticsPage";
import {UsersBoostsPage} from "./pages/subsection/UsersBoostsPage";


const router = createBrowserRouter([
    {path: "/", element: <HomePage/>},
    {path: "login", element: <HomePage/>},
    {path: "users", element: <Layout><UsersPage/></Layout>},
    {path: "users/:usersUUID/boosts/", element: <Layout><UsersBoostsPage/></Layout>},
    {path: "campaigns", element: <Layout><CampaignsPage/></Layout>},
    {path: "campaigns-off-app", element: <Layout><CampaignsPage/></Layout>},
    {path: "sponsors", element: <Layout><SponsorsPage/></Layout>},
    {path: "boosts", element: <Layout><BoostsPage/></Layout>},
    {path: "boosts/:campaignsUUID", element: <Layout><BoostsDetailPage/></Layout>},
    {path: "reports/:campaignsUUID", element: <Layout><ReportsPage/></Layout>},
    {path: "analytics", element: <Layout><AnalyticsPage/></Layout>},

    {path: "logout", element: <LogoutPage/>},
]);

function App() {
    return (
        <>
            <RouterProvider router={router}/>
        </>
    );
}

export default App;
