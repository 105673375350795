import {useEffect, useState} from "react";
import {DSYForm, DSYTable} from "../../styles/GlobalStyles";
import imageCompression from "browser-image-compression";
import {updateCampaign} from "../../services/apiCampaignsService";
import {fetchSponsors} from "../../services/apiSponsors";
import useOnceEffect from "../../hooks/userOnceEffect";


export function CampaignsEditPage({campaign, onCloseCallback, onUpdateItem}: {
    campaign: any,
    onCloseCallback: Function,
    onUpdateItem: Function
}) {

    const [sponsors, setSponsors] = useState<any>([]);
    const [didLoadData, setDidLoadData] = useState(false);
    const [values, setValues] = useState<any>({});


    let sectionsToEdit = [
        {'title': 'Name', 'input_type': 'text', 'value': '', 'name': 'name'},
        {'title': 'Platform', 'input_type': 'radio', 'value': '', 'name': 'platforms_id'},
        {'title': 'URL', 'input_type': 'text', 'value': '', 'name': 'url'},
        {'title': 'Image', 'input_type': 'file', 'accept_types': 'image/*', 'value': '', 'name': 'image'},

        {'title': 'Total Budget', 'input_type': 'number', 'value': '', 'name': 'purse'},
        {'title': 'Reward: Cash', 'input_type': 'number', 'value': '', 'name': 'cash_value'},
        {'title': 'Action/Reward', 'input_type': 'select', 'value': '', 'name': 'single_action_kind'},
        {'title': 'Sponsor', 'input_type': 'select', 'value': '', 'name': 'sponsor.uuid'},

        {'title': 'Start Date (Time Zone: EST)', 'input_type': 'datetime-local', 'value': '', 'name': 'date_start_EST'},
        {'title': 'End Date (Time Zone: EST)', 'input_type': 'datetime-local', 'value': '', 'name': 'date_end_EST'},
        {'title': 'Active', 'input_type': 'checkbox', 'value': 'checked', 'name': 'active'},
        {
            'title': 'Only Visible to Internal Team',
            'input_type': 'checkbox',
            'value': 'checked',
            'name': 'only_visible_to_team'
        },
    ]
    const isNewItem = campaign.hasOwnProperty('uuid') && campaign.uuid === 'new';

    const getSponsors = async () => {
        try {
            const data = await fetchSponsors();
            setSponsors([]);
            if (data.hasOwnProperty('items')) {
                let items = data['items'];
                setSponsors(items);
            }

        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    useOnceEffect(() => {
        getSponsors();
    });

    useEffect(() => {
        let data: any = {};

        sectionsToEdit.forEach((section) => {
            //if name has a dot, it's a nested object
            if (section.name.includes('.')) {
                let parts = section.name.split('.');
                let first = parts[0];
                let second = parts[1];
                if (campaign[first] != null) {
                    data[first + '.' + second] = campaign[first][second];
                }
            } else {
                data[section.name] = campaign[section.name];

            }
        });
        setValues(data);
        setDidLoadData(true);

    }, []);


    const handleImageChange = async (e: any) => {
        const file = e.target.files[0];
        if (file && file.type.startsWith('image/')) {


            const options = {
                maxSizeMB: 1, // Maximum file size (in MB)
                maxWidthOrHeight: 1200, // Maximum dimensions
                // onProgress: (e) => console.log(e)
            };
            try {

                const compressedFile = await imageCompression(file, options);
                if (compressedFile) {
                    let reader = new FileReader();
                    reader.onloadend = () => {
                        setValues({...values, 'image': reader.result});
                    };
                    reader.readAsDataURL(compressedFile);

                } else {
                }
            } catch (error) {
                console.error(error);

            }

        }
    };
    const handleCancel = (e: any) => {
        e.preventDefault();
        onCloseCallback();
    }

    const handleSave = async (e: any) => {
        e.preventDefault();

        let selectedPlatform = parseInt(values.platforms_id, 10);
        //validation
        //if platform_id == 1 (tiktok), url must start with https://www.tiktok.com/@ , if https://vm.tiktok.com/ , show error about expected url
        if (values.url != null && values.url.toString().trim().length > 4) {
            if (selectedPlatform === 1 && !values.url.startsWith('https://www.tiktok.com/@')) {
                //if empty, it's okay
                if (values.url.toString().trim() === '') {
                } else {
                    alert('TikTok URL must start with https://www.tiktok.com/@');
                    return;
                }
            }
            // if platform_id == 2 (instagram), url must start with https://www.instagram.com/ , if https://www.instagram.com/p/ , show error about expected url
            if (selectedPlatform === 2 && !values.url.startsWith('https://www.instagram.com/')) {
                if (values.url.toString().trim() === '') {
                } else {
                    alert('Instagram URL must start with https://www.instagram.com/');
                    return;
                }

            }
        }
        let sendValues = {...values};
        sendValues.uuid = campaign.uuid;
        console.table(sendValues);

        var result = await updateCampaign(campaign.uuid, sendValues);
        console.log('saved', result);
        if (result.status === 'error') {
            alert(result.message);
        } else {

            campaign = {...campaign, ...values};
            onUpdateItem(campaign);
            if (isNewItem) {
                onCloseCallback({'reload': true});
            } else {
                onCloseCallback();
            }
        }
    }

    if (!didLoadData) {
        return <>
            Loading...
        </>
    }


    return (
        <div>
            <div style={{
                display: 'flex',
                gap: 20,
                alignItems: 'center',
                marginBottom: 20
            }}>
                <img src={values.image}
                     width={120} style={{
                    objectFit: 'cover',
                    borderRadius: '5%',
                    aspectRatio: 0.46153846

                }}/>
                {/*<textarea>*/}
                {/*    {JSON.stringify(campaign, null, 2)}*/}
                {/*</textarea>*/}
                <input type={'file'} accept={'image/*'} onChange={handleImageChange}/>
                <div style={{
                    display: 'flex',
                    gap: 10,
                    flexDirection: 'row'
                }}>
                    {/*if playable_video_url, show video player*/}
                    {campaign.playable_video_url != null && campaign.playable_video_url.length > 0 &&
                        <video
                            controls
                            height="300"
                            style={{
                                objectFit: 'scale-down',
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                aspectRatio: 9 / 16
                            }}
                        >
                            <source src={campaign
                                .playable_video_url} type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                    }
                    {campaign['playable_gif_url'] && <img
                        height={200}
                        style={{
                            objectFit: 'scale-down',
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                        }}
                        src={campaign['playable_gif_url']}/>}
                </div>

            </div>
            <h2>Edit </h2>
            <DSYForm onSubmit={handleSave}>
                <DSYTable>
                    {sectionsToEdit.map((section, index) => {
                        let inner = null;
                        if (section.input_type === 'checkbox') {
                            inner = <input type={section.input_type} name={section.name}
                                           checked={values[section.name]}
                                           onChange={(e) => {
                                               setValues({...values, [section.name]: e.target.checked})
                                           }}/>

                        } else if (section.input_type === 'file') {
                            return null;
                        } else if (section.input_type === 'select') {
                            if (section.name === 'single_action_kind') {
                                let options = [
                                    {'title': 'Like & Comment', 'value': 'like_and_comment'},
                                    {'title': 'Repost', 'value': 'repost'},
                                ];
                                inner = <>
                                    <select name={section.name} value={values[section.name]}
                                            onChange={(e) => {
                                                setValues({...values, [section.name]: e.target.value})
                                            }}>
                                        {options.map((option, index) => {
                                            let selected = values[section.name] === option.value;
                                            return <option key={index}
                                                           selected={selected}
                                                           value={option.value}>{option.title}</option>
                                        })}
                                    </select>
                                </>
                            } else if (section.name === 'sponsor.uuid') {
                                let options = sponsors;
                                inner = <>
                                    <select name={section.name} value={values[section.name]}
                                            onChange={(e) => {
                                                setValues({...values, [section.name]: e.target.value})
                                            }}>
                                        {options.map((option: any, index: number) => {
                                            let selected = values[section.name] === option.uuid;
                                            return <option key={index}
                                                           selected={selected}
                                                           value={option.uuid}>{option.name}</option>
                                        })}
                                    </select>
                                </>
                            }
                        } else if (section.input_type === 'radio') {

                            if (section.name === 'platforms_id') {
                                let options = [
                                    {'title': 'TikTok', 'value': 1},
                                    {'title': 'Instagram', 'value': 2},
                                ];
                                inner = <>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 20
                                    }}>
                                        {options.map((option, index) => {
                                            let selected = parseInt(values[section.name], 10) === option.value;
                                            return <div key={index} style={{
                                                display: 'flex',
                                                gap: 10,
                                                alignItems: 'center',
                                            }}>
                                                <input type={section.input_type} name={section.name}
                                                       value={option.value}
                                                       checked={selected}
                                                       onChange={(e) => {
                                                           setValues({
                                                               ...values,
                                                               [section.name]: parseInt(e.target.value, 10)
                                                           })
                                                       }}/>
                                                <div>
                                                    {option.title}
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </>
                            } else {

                                inner = <input type={section.input_type} name={section.name}
                                               value={values[section.name]}
                                               onChange={(e) => {
                                                   setValues({...values, [section.name]: e.target.value})
                                               }}/>
                            }
                        } else {


                            inner = <input type={section.input_type} name={section.name}
                                           value={values[section.name]}
                                           onChange={(e) => {
                                               setValues({...values, [section.name]: e.target.value})
                                           }}/>
                        }


                        return (
                            <tr key={index}>
                                <td>{section.title}</td>
                                <td>
                                    {inner}
                                </td>
                            </tr>
                        );
                    })}
                </DSYTable>
                <div className={'cta-area'}>
                    <a onClick={handleCancel}>Cancel</a>
                    <button type="submit">Submit</button>
                </div>
            </DSYForm>
        </div>
    );
}