import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import {useRef, useState} from "react";
import useOnceEffect from "../hooks/userOnceEffect";
import {DSYMainWrapper, DSYMainWrapperInner, DSYSearchBar, DSYTable} from "../styles/GlobalStyles";
import {formatNumber, sortItems} from "../components/Utils";
import {fetchCampaigns, updateCampaignProperties} from "../services/apiCampaignsService";
import styled from "styled-components";
import {CampaignsEditPage} from "./subsection/CampaignsEditPage";
import {friendlyPlatformName, WEBSITE_URL} from "../config/consts";
import {CommentsEditPage} from "./subsection/CommentsEditPage";
import {useNavigate} from "react-router-dom";
import {DSYButton} from "../components/Layout";
import {UsersPage} from "./UsersPage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faComment, faPenToSquare} from "@fortawesome/free-regular-svg-icons";
import {faChartLine, faRocket} from "@fortawesome/free-solid-svg-icons";
import {Tooltip} from "@mui/material";


export const TDNoWrap = styled.td`
    white-space: nowrap;
`;

export function CampaignsPage() {
    const [didLoad, setDidLoad] = useState(false);
    const [campaigns, setCampaigns] = useState<any>([]);
    const [selectedCampaign, setSelectedCampaign] = useState<any>({});
    const [filteredCampaigns, setFilteredCampaigns] = useState<any>([]);
    const searchRef = useRef<HTMLInputElement>(null);
    const [sortBy, setSortBy] = useState('date_startU');
    const [appInfo, setAppInfo] = useState<any>({});
    const [openSlug, setOpenSlug] = useState<null | string>(null);
    const navigate = useNavigate();
    const [focuedOnGroupInputItemId, setFocuedOnGroupInputItemId] = useState<null | string>(null);


    const getCampaigns = async () => {
        try {
            const data = await fetchCampaigns();
            setCampaigns([]);
            if (data.hasOwnProperty('items')) {
                let items = data['items'];
                setCampaigns(items);
                setFilteredCampaigns(items);
            }
            if (data.hasOwnProperty('app_info')) {
                setAppInfo(data['app_info']);
            }
            setDidLoad(true);
        } catch (error) {
            console.error('Error fetching campaign:', error);
        }
    };
    useOnceEffect(() => {
        getCampaigns();
    });


    let onEditCampaign = (item: any) => {
        setSelectedCampaign(item);
        setOpenSlug('edit');
    }
    let onEditCampaignComments = (item: any) => {
        setSelectedCampaign(item);
        setOpenSlug('comment-edit');
    }

    const fullScreen = false;


    const handleClose = (event: object, reason: String) => {

        if (reason === 'backdropClick') {
            // return;
        }
        setOpenSlug(null);
    };

    const handleOnCloseRequest = (e: any) => {
        setOpenSlug(null);
        if (e && e.hasOwnProperty('reload') && e['reload'] === true) {
            //reload
            getCampaigns();
        }
    }

    const handleSearch = () => {
        let search = searchRef.current?.value;
        filterCampaigns(search);
    }
    const handleUpdateCampaign = (item: any) => {


        let index = campaigns.findIndex((u: any) => u.uuid === item.uuid);
        if (index > -1) {
            let updated: any[] = [...campaigns];
            updated[index] = item;
            setCampaigns(updated);
            setFilteredCampaigns(updated);
        }
    }

    const filterCampaigns = (search?: string) => {
        if (!search) {
            sortItems(sortBy, campaigns);
            setFilteredCampaigns(campaigns);
            return;
        }
        let term = search.toLowerCase();
        let fieldsToSearch = ['name', 'url'];
        let filtered = campaigns.filter((item: any) => {
            if (item != null) {
                let match = false;
                fieldsToSearch.forEach((field) => {
                    if (item[field]) {
                        if (item[field].toLowerCase().includes(term)) {
                            match = true;
                        }
                    }
                });
                if (match) {
                    return true;
                }
            }
            return false;
        });
        //sort
        sortItems(sortBy, filtered);
        setFilteredCampaigns(filtered);
    }


    const handleSortByClick = (field: string) => {
        if (sortBy === field) {
            setSortBy(field + '_desc');
        } else {
            setSortBy(field);
        }
        filterCampaigns();
    }

    const handleToggleActive = async (item: any) => {
        //toggle active
        item['active'] = item['active'] === 1 ? 0 : 1;
        //update state
        await updateCampaignProperties(item['uuid'], {'active': item['active']});
        handleUpdateCampaign(item);

        //update
        // handleUpdateCampaign
    }

    const handleUpdateGroupNumber = async (item: any, value: number | null) => {
        //set a debouce
        //toggle active
        if (value == null || value === 0) {
            value = null;
        }
        item['group_number'] = item['group_number'] = value;
        item['group_number_temp'] = item['group_number'];

        //update state
        await updateCampaignProperties(item['uuid'], {'group_number': item['group_number']});
        setCampaigns([...campaigns]);

    }

    const unixTimeInSecondsToNYCTime = (unixTime: number) => {
        let date = new Date(unixTime * 1000);
        let formatter = new Intl.DateTimeFormat('en-US', {
            timeZone: 'America/New_York',
            hour12: true,
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
        });
        //use moment.js
        // return moment(date).format('MMMM, d Y h:mm:ss A z');

        return formatter.format(date) + ' (EST)';
    }

    const friendlyCampaignAction = (action: string) => {
        switch (action) {
            case 'like_and_comment':
                return 'Like & Comment';
            case 'repost':
                return 'Repost';
            case 'other':
                return 'Other';
            default:
                return 'Unknown';
        }
    }


    const possibleBoostSlots = (cash: number, purse: number) => {
        let v = 0;
        try {
            if (cash > 0) {
                v = purse / cash;
            }
        } catch (e) {
            v = 0;
        }
        return Math.floor(v);
    }
    const filteredForSection = (section: string) => {


        let itemsToUse = filteredCampaigns;
        if (section === 'all') {
            return itemsToUse;
        }


        return itemsToUse.filter((item: any) => {

                //upcoming_live = active and date_start <= now && date_end >= now
                //completed = date_end >= now
                //internal_only = internal_only = 1
                if (section === 'upcoming_live') {
                    if (item['only_visible_to_team'] === 1) {
                        return false;
                    }
                    let nowValue = Date.now() / 1000;
                    return (
                        (item['date_startU'] <= nowValue && item['date_endU'] >= nowValue)
                        ||
                        (item['date_startU'] >= nowValue)
                    );
                } else if (section === 'completed') {
                    if (item['only_visible_to_team'] === 1) {
                        return false;
                    }
                    // return item['date_endU'] >= Date.now();
                    return true;
                } else if (section === 'internal_only') {
                    return item['only_visible_to_team'] === 1;
                }
                return false;

            }
        );
    }

    const handleOptionToPickUsers = (item: any) => {
        setSelectedCampaign(item);
        setOpenSlug('pick-users');
    }

    return (
        <>
            <DSYMainWrapper>
                <DSYMainWrapperInner>
                    <div style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: '40px',
                    }}><h2>Campaigns</h2>
                        <button
                            style={{
                                height: '30px',
                                marginBottom: '20px',
                            }}
                            onClick={() => {
                                onEditCampaign({'uuid': 'new'});
                            }}>Add Campaign
                        </button>
                    </div>
                    <DSYSearchBar>
                        <input type="text" ref={searchRef} placeholder="Search..." onKeyUp={handleSearch}/>
                        {/*<button>Search</button>*/}
                    </DSYSearchBar>
                    {openSlug && <Dialog
                        fullScreen={fullScreen}
                        open={true}

                        // fullWidth={true}
                        maxWidth={'xl'}
                        // fullWidth={openSlug === 'pick-users'}
                        onClose={handleClose}

                        aria-labelledby="responsive-dialog-title"
                    >
                        <DialogContent>
                            {selectedCampaign && (
                                (openSlug === 'comment-edit' ? <CommentsEditPage campaign={selectedCampaign}
                                                                                 onUpdateItem={handleUpdateCampaign}
                                                                                 onCloseCallback={handleOnCloseRequest}/> :
                                    openSlug === 'pick-users' ? <UsersPage campaign={selectedCampaign}
                                                                           onUpdateItem={handleUpdateCampaign}
                                                                           onCloseCallback={handleOnCloseRequest}/> :
                                        openSlug === 'edit' ? <CampaignsEditPage campaign={selectedCampaign}
                                                                                 onUpdateItem={handleUpdateCampaign}
                                                                                 onCloseCallback={handleOnCloseRequest}/> : null))
                            }
                        </DialogContent>
                        {/*<DialogActions>*/}
                        {/*    <Button autoFocus onClick={hanleOnClickToClose}>*/}
                        {/*        Disagree*/}
                        {/*    </Button>*/}
                        {/*    <Button onClick={hanleOnClickToClose} autoFocus>*/}
                        {/*        Agree*/}
                        {/*    </Button>*/}
                        {/*</DialogActions>*/}
                    </Dialog>}
                    {
                        [
                            {'title': 'Upcoming/Live Campaigns', 'slug': 'upcoming_live'},
                            {'title': 'Completed Campaigns', 'slug': 'completed'},
                            {'title': 'Internal Only', 'slug': 'internal_only'},
                        ].map((section: any) => {

                                let itemsForSection = filteredForSection(section['slug']);
                                //handle sort by

                                return <>
                                    <h3>{section['title']}</h3>
                                    <DSYTable>
                                        <thead>
                                        <tr>{
                                            [
                                                {'title': 'Options', 'field': 'options', 'sort': false},
                                                {'title': 'Name', 'field': 'name', 'sort': true},
                                                {'title': 'Group#', 'field': 'group_number', 'sort': true},
                                                {'title': 'Platform', 'field': 'platforms_id', 'sort': true},
                                                {'title': 'Visible #Users', 'field': 'x', 'sort': false},
                                                {'title': 'Action', 'field': 'single_action_kind', 'sort': true},
                                                {'title': 'Sponsor', 'field': 'x', 'sort': false},
                                                {'title': 'URL', 'field': 'url', 'sort': false},
                                                {'title': 'Image', 'field': 'img_url', 'sort': false},
                                                {'title': 'Video', 'field': 'playable_video_url', 'sort': true},
                                                {'title': 'Total Budget', 'field': 'total_budget', 'sort': true},
                                                {'title': 'Reward', 'field': 'reward', 'sort': true},
                                                {'title': 'Start Date', 'field': 'start_date', 'sort': true},
                                                {'title': 'End Date', 'field': 'end_date', 'sort': true},
                                                {'title': 'Created', 'field': 'created', 'sort': true},
                                                {'title': 'Options', 'field': 'options', 'sort': false},
                                            ].map((field) => {
                                                    let active = sortBy === field['field'] || sortBy === field['field'] + '_desc';
                                                    let canSort = field['sort'] !== false;
                                                    return (
                                                        <th
                                                            key={field['field']}
                                                            style={
                                                                canSort ?
                                                                    {
                                                                        cursor: 'pointer',
                                                                        userSelect: 'none',
                                                                        color: active ? 'blue' : 'black'
                                                                    } : {}}
                                                            onClick={() => canSort ? handleSortByClick(field['field']) : null}>{field['title']}</th>
                                                    );
                                                }
                                            )
                                        }
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {itemsForSection.map((item: any) => {

                                            let visibleDisplay = '';
                                            if (appInfo['totalUsers'] != null) {
                                                visibleDisplay = item['visible_to_user_count'] + ' / ' + appInfo['totalUsers'];
                                            }

                                            let slots = possibleBoostSlots(item['cash_value'], item['purse']);
                                            let isActive = item['active'] === 1;

                                            if (item['group_number_temp'] == null) {
                                                item['group_number_temp'] = item['group_number'];
                                            }
                                            let tempGroupNumber = item['group_number_temp'];
                                            if (isNaN(tempGroupNumber)) {
                                                tempGroupNumber = null;
                                            }

                                            return (
                                                <tr
                                                    style={{
                                                        backgroundColor: isActive ? '#00ff0008' : '#ff000008',
                                                    }}
                                                    key={item['uuid']}>
                                                    <td style={{
                                                        whiteSpace: 'nowrap',

                                                    }}>
                                                        <div>
                                                            <div>
                                                                <Tooltip title={'Edit Campaign'} disableInteractive={true}>
                                                                    <button onClick={() => {
                                                                        onEditCampaign(item);
                                                                    }}><FontAwesomeIcon icon={faPenToSquare}/>
                                                                    </button>
                                                                </Tooltip>

                                                                <Tooltip title={'Edit Comments'} disableInteractive={true}>
                                                                    <button onClick={() => {
                                                                        onEditCampaignComments(item);
                                                                    }}><FontAwesomeIcon icon={faComment}/>
                                                                    </button>
                                                                </Tooltip>
                                                            </div>
                                                            <div>
                                                                <Tooltip title={'View Boosts'} disableInteractive={true}>
                                                                    <button onClick={() => {
                                                                        window.open('/boosts/' + item['uuid'], '_blank');
                                                                    }}><FontAwesomeIcon icon={faRocket}/>
                                                                    </button>
                                                                </Tooltip>

                                                                <Tooltip title={'See Stats'} disableInteractive={true}>
                                                                    <button
                                                                        onClick={() => {
                                                                            window.open('/reports/' + item['uuid'], '_blank');
                                                                        }}>
                                                                        <FontAwesomeIcon icon={faChartLine}/>
                                                                    </button>
                                                                </Tooltip>
                                                            </div>
                                                            <div>
                                                                <Tooltip title={'Change Status'} disableInteractive={true}>
                                                                    {isActive ?
                                                                        <DSYButton
                                                                            className={'active'}
                                                                            onClick={() => handleToggleActive(item)}>Active</DSYButton> :
                                                                        <DSYButton
                                                                            className={'paused'}
                                                                            onClick={() => handleToggleActive(item)}>Paused</DSYButton>}
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                    </td>

                                                    <td style={{
                                                        fontWeight: 'bold'
                                                    }}>{item['name']}<br/>
                                                        <a href={WEBSITE_URL + '/c/' + item['short_link']} style={{
                                                            fontSize: '10px',
                                                            color: 'gray',
                                                        }} target={'_blank'}>Short link</a></td>
                                                    <td>
                                                        <input type={'text'}
                                                               value={tempGroupNumber}
                                                               onFocus={() => {
                                                                   setFocuedOnGroupInputItemId(item['uuid']);
                                                               }}
                                                               onBlur={() => {
                                                                   // setFocuedOnGroupInputItemId(null);
                                                               }}
                                                               onChange={(e) => {
                                                                   //set item temp group number
                                                                   item['group_number_temp'] = parseInt(e.target.value);
                                                                   //update this in the state
                                                                   setCampaigns([...campaigns]);


                                                               }}
                                                               placeholder={'0'}/>
                                                        {focuedOnGroupInputItemId === item['uuid'] && <button
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                // alert('Update group number to ' + tempGroupNumber);
                                                                handleUpdateGroupNumber(item, parseInt(item['group_number_temp']));
                                                                setFocuedOnGroupInputItemId(null);
                                                            }}>Update</button>}

                                                    </td>
                                                    <td>{friendlyPlatformName(item['platforms_id'])}</td>
                                                    <td>Slots:{slots}<br/>{visibleDisplay} <br/>
                                                        <button
                                                            style={{
                                                                whiteSpace: 'nowrap',
                                                                backgroundColor: 'lightgreen',
                                                            }}

                                                            onClick={() => {
                                                                handleOptionToPickUsers(item);
                                                            }}>Pick&nbsp;Users 😀
                                                        </button>
                                                    </td>
                                                    <td>{friendlyCampaignAction(item['single_action_kind'])}</td>
                                                    <td>{item['sponsor'] ? item['sponsor']['name'] ?? '' : ''}</td>
                                                    <td style={{
                                                        maxWidth: '200px',
                                                        minWidth: '200px',
                                                        whiteSpace: 'wrap',
                                                        wordBreak: 'break-all',
                                                        fontSize: '10px',
                                                    }}>{item['url']}</td>
                                                    <td>{item['img_url'] &&
                                                        <img src={item['img_url']} width={30} height={30}
                                                             style={{
                                                                 objectFit: 'scale-down',
                                                             }}
                                                        />}
                                                    </td>
                                                    <td>
                                                        {item['playable_gif_url'] && <img
                                                            height={130}
                                                            style={{
                                                                objectFit: 'scale-down',
                                                                border: '1px solid #ccc',
                                                                borderRadius: '4px',
                                                            }}
                                                            src={item['playable_gif_url']}/>}

                                                        {item['playable_video_url'] ? <>✅</> : <>NO</>}</td>
                                                    <td>${formatNumber(item['purse'])}</td>
                                                    <td>${formatNumber(item['cash_value'])}</td>

                                                    <TDNoWrap>{unixTimeInSecondsToNYCTime(item['date_startU'])}</TDNoWrap>
                                                    <TDNoWrap>{unixTimeInSecondsToNYCTime(item['date_endU'])}</TDNoWrap>
                                                    <TDNoWrap>{unixTimeInSecondsToNYCTime(item['createdU'])}</TDNoWrap>
                                                    {/*<td>{*/}
                                                    {/*    <textarea*/}
                                                    {/*        style={{*/}
                                                    {/*            height: '200px',*/}
                                                    {/*            width: '300px',*/}
                                                    {/*            fontSize: '10px',*/}
                                                    {/*        }}*/}
                                                    {/*    >{JSON.stringify(item, null, 2)}</textarea>*/}
                                                    {/*}</td>*/}

                                                    <td>
                                                        <button>Delete</button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                        </tbody>
                                    </DSYTable>
                                    <div style={{height: '20px'}}/>
                                    {/*<CampaignGantt dataToUse={itemsForSection}/>*/}
                                    <div style={{height: '20px'}}/>
                                </>
                            }
                        )}
                </DSYMainWrapperInner>
            </DSYMainWrapper>
        </>
    );
}