import {apiClient} from "./apiService";

export const fetchSponsors = async () => {
    const response = await apiClient.get('/sponsors/all');
    return response.data;
};

export const updateSponsor = async (id: string, data: any) => {
    const response = await apiClient.post('/sponsors/update/' + id, data);
    return response.data;
}
